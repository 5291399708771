import { formatDate, liveScore } from '../../bets/utils/functions';
import store from '@/store';
import { MatchType } from '../../bets/utils/types';
import { useTranslation } from 'react-i18next';

export const EXTEND = 2;

const buildBetsList = (
  bets: any[],
  isExtended: boolean,
  language: string,
  ticketSettled: boolean,
  liveMatches: { [idMatch: string]: MatchType },
) => {
  const res: any[] = [];

  const now = +new Date();
  const state = store.getState();
  const betsState: any = state.bets;
  let sportName: string = '';
  let categoryName: string = '';
  let tournamentName: string = '';
  let liveStatus: string = '';

  bets.forEach((bet) => {
    const isLive = bet.startAt < now && bet.status !== 'WON' && bet.status !== 'LOST' && !ticketSettled;
    if (isLive) {
      sportName = betsState.live?.sports[bet.idSport]?.sportName ?? bet.tournamentName ?? '';
      categoryName = betsState.live?.categories[bet.idCategory]?.categoryName ?? '';
      tournamentName = betsState.live?.tournaments[bet.idTournament]?.tournamentName ?? bet.tournamentName ?? '';
      liveStatus = betsState.live?.matches[bet.idMatch];
    } else {
      sportName = betsState.prematch?.sports[bet.idSport]?.sportName ?? bet.sportName ?? '';
      categoryName = betsState.prematch?.categories[bet.idCategory]?.categoryName ?? '';
      tournamentName = betsState.prematch?.tournaments[bet.idTournament]?.tournamentName ?? bet.tournamentName ?? '';
    }

    let scoreInfo = null;
    if (bet.mType === 'live' && liveMatches[bet.idMatch]) {
      const match = liveMatches[bet.idMatch];
      const ls = liveScore(match, match.currentStatus);
      if (Array.isArray(ls.intervals) && ls.intervals.length > 0) {
        ls.intervals = ls.intervals.map((interval) => {
          return { percent: interval };
        });
      }
      scoreInfo = ls;
    }

    let results: any = [];
    if (bet.results && bet.results.length > 0) {
      const tmp = bet.results[0];
      if (tmp.halfResult && tmp.halfResult.length > 0) {
        results = tmp.halfResult.map((r: any) => parseFloat(r));
      }
    }

    res.push({
      mType: bet.mType,
      isLive: isLive,
      liveStatus: liveStatus,
      startAtMs: bet.startAt,
      startAt: formatDate(bet.startAt, language, false),
      teams: bet.teams,
      status: bet.status,
      statusClassName: bet.status === 'WON' ? 'won' : bet.status === 'LOST' ? 'lost' : isLive ? 'live' : 'pre',
      idSport: bet.idSport,
      idCategory: bet.idCategory,
      idTournament: bet.idTournament,
      idMatch: bet.idMatch,
      idBet: bet.idBet,
      idMatchBet: bet.idMatchBet,
      idBetOutcome: bet.idBetOutcome,
      idMatchBetOutcome: bet.idMatchBetOutcome,
      sportName: sportName,
      categoryName: categoryName,
      tournamentName: tournamentName,
      betDisplayName: bet.betDisplayName,
      outcomeDisplayName: bet.outcomeDisplayName,
      oddValue: bet.oddValue,
      scoreInfo: scoreInfo,
      results: results,
      betType: bet?.betBuilderStakes?.length > 0 ? 'betBuilder' : 'normal',
      betBuilderStakes: bet?.betBuilderStakes,
    });
  });

  if (!isExtended && res.length > EXTEND) {
    return res.splice(0, EXTEND);
  }

  return res;
};

export const formatValue = (v: string, strip: boolean = false) => {
  const f = parseFloat(v);
  if (isNaN(f)) return 0;
  let r = f.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (strip) r = r.replace(/\.00$/, '');
  return r;
};

export const buildTicketLists = (
  tickets: any[],
  extended: any,
  language: string,
  t: any,
  isWinnerFun: boolean,
  cashoutState: any,
  liveMatches: { [idMatch: string]: MatchType },
) => {
  const res: any[] = [];

  tickets.forEach((ticket) => {
    const type = ticket.type;
    const noBets = ticket.bets.length;

    const isExtended = extended[ticket.idHash] != null ? extended[ticket.idHash] : false;

    /*
    clientVal: {
      "free_bet_code": "0e87c99c-ab64-49d1-89b7-54b648b18415",
      "free_bet_name": "Test bulk free bet",
      "free_bet_pid": 194,
      "free_bet_redeemable": 1
    }
    */

    const isFreeBet = ticket.clientVal?.free_bet_code != null && ticket.clientVal?.free_bet_code !== '';
    const isFreeBetRedeemable = ticket.clientVal?.free_bet_redeemable === 1;
    const freeBetName = 'FREE BET';

    const betsList = buildBetsList(
      ticket.bets,
      isExtended,
      language,
      ticket.status === 'WON' || ticket.status === 'LOST',
      liveMatches,
    );

    const now = +new Date();
    let hasActiveMatches = false;
    betsList.forEach((bet) => {
      if (bet.mType === 'prematch' && bet.startAtMs > now) {
        hasActiveMatches = true;
      } else if (bet.mType === 'live' && bet.startAtMs + 4 * 60 * 60 > now) {
        hasActiveMatches = true;
      }
    });

    res.push({
      sharedTicket: (ticket.flags & 64) !== 0,
      hash: ticket.idHash,
      cashoutState: isFreeBet ? null : cashoutState?.tickets[ticket.idHash] ?? null,
      cashouted: ticket.cashouted,
      payout: formatValue(
        isFreeBet && !isFreeBetRedeemable && ticket.payout > ticket.stake
          ? ticket.payout - ticket.stake
          : ticket.payout,
      ),
      createdAt: ticket.createdAt,
      status: ticket.status, // OPEN WON LOST
      extended: isExtended,
      systemType: ticket.systemType,
      type: t(type) + (ticket.systemType.length && ticket.systemType[0] ? ' ' + ticket.systemType[0] : ''),
      totalBets: noBets,
      extendedBets: noBets > EXTEND ? noBets - EXTEND : 0,
      bets: betsList,
      totalOdd: formatValue(ticket.totalOdd),
      winning: formatValue(
        isFreeBet && !isFreeBetRedeemable && ticket.winning > ticket.stake
          ? ticket.winning - ticket.stake
          : ticket.winning,
      ),
      stake: formatValue(ticket.stake, true),
      currency: isWinnerFun ? 'W' : 'Lei',
      isFreeBet: ticket.clientVal?.free_bet_code != null && ticket.clientVal?.free_bet_code !== '',
      freeBetName: freeBetName,
      freeBetRedeemable: ticket.clientVal?.free_bet_redeemable,
      hasActiveMatches: hasActiveMatches,
    });
  });

  return res;
};
