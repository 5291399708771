import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../store';
import { useTranslation } from 'react-i18next';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { ConfigReducer as AllCountriesReducer } from '@/store/slices/allCountries';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import Flags from '@/modules/lotto/assets/countries';

import './index.scss';
import Axios from 'axios';

type CountrySelectorProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    countryCode: string;
    dsType: string;
  };
};

type ModuleStateProps = {
  currentCountry: any;
  oldCountry: string;
  isValid: boolean;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const CountrySelector = (componentProps: CountrySelectorProps) => {
  let props = componentProps;
  const { t } = useTranslation();
  const allCountries = useAppSelector((state) => state.allCountries as AllCountriesReducer);
  const dataElementContext = React.useContext(DataElementContext);

  [props] = processComponentProps(props, dataElementContext);

  const [state, setState] = React.useState<ModuleStateProps>({
    currentCountry: '',
    oldCountry: '',
    isValid: false,
  });

  React.useEffect(() => {
    if (allCountries?.countries && allCountries?.currentCountry) {
      const selectedCountry = allCountries?.countries.find(
        (country: any) => country.countryCode === allCountries?.currentCountry,
      );

      setState((prevState) => ({
        ...prevState,
        currentCountry: selectedCountry,
      }));
    }
  }, [allCountries]);

  React.useEffect(() => {
    if (props?.properties?.countryCode) {
      const oldCountry = state.currentCountry;
      const selectedCountry = allCountries?.countries.find(
        (country: any) => country.countryCode === props?.properties?.countryCode?.toUpperCase?.(),
      );
      setState((prevState) => ({
        ...prevState,
        currentCountry: selectedCountry,
        oldCountry: oldCountry?.countryCode,
      }));
    }
  }, [props?.properties?.countryCode]);

  const onValidatePhone = async (value: string) => {
    if (value) {
      try {
        const response = await Axios.get(window.config.front_url + '/app/validatePhone', {
          params: {
            phoneNumber: '+' + state?.currentCountry?.phoneCode + value?.replace(/\s/g, ''),
            countryCode: state.currentCountry.countryCode,
          },
        });

        if (response.data.result) {
          setState((prevState) => ({
            ...prevState,
            isValid: response.data.result.isValid,
          }));
          const element: HTMLElement | null = document.querySelector('.wl-input-phone-number');
          const errorMessage: HTMLElement | null = document.querySelector('.err-txt');
          if (!response.data.result.isValid) {
            if (element) element.classList.add('st-error');
            if (errorMessage) errorMessage.innerHTML = t('Invalid phone number.');
          } else {
            if (element) element.classList.remove('st-error');
          }
        }
      } catch (err: any) {
        const errResp = { error: err.toString() };
      }
    }
  };

  const contextValue = {
    countries: allCountries.countries,
    currentCountry: state.currentCountry,
    oldCountry: state.oldCountry,
    onValidatePhone: onValidatePhone,
    isValid: state.isValid,
    parentContext: dataElementContext,
    countryUrl: props?.properties?.countryCode
      ? (Flags as { [id: string]: string })?.[props?.properties?.countryCode] ?? ''
      : '',
  };

  // console.log(props, contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default CountrySelector;
