import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { tournamentActivate, tournamentEnroll } from '../../../modules/tournaments-missions/store/actions/tournament';
import {
  loadTournamentsGroups,
  toggleHappyHoursSelected,
} from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import { loadPlayerLeaderBoard } from '../../../modules/tournaments-missions/store/actions/leader_board';
import { gameIdsToObjects } from '../../../utils/gameIdsToObjects';
import DefaultAvatar from '../_common/assets/img-avatar-male.png';
import fetchGamesInfo from '../../../utils/games-info-api-cache';
import { getMomentumBonuses } from '../../../modules/momentum/store/actions/momentum';
import { setInGameHeaderSelection } from '@/modules/casino/store/actions/application';
import { fetchChallenges, resetChallengeCelebration, resetChallengeProgressIncrease } from '@/store/slices/challenges';
import { Group } from '../tournament-group';

import { momentumProgressDetails, starThresholds } from '../../../modules/momentum/utils/functions';
import { formatCurrency } from '@/modules/bets/utils/formatters';
import { formatProgressString } from '../challenges';

import './index.scss';

type GameHeaderProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

type StateProps = {
  hasActive: boolean;
  hasEnrolled: boolean;
  tournament: any;
  group: Group | null;
  activeTournaments: any;
  // groupsLoaded: boolean;
};

type AppSelectorState = {
  authentication?: any;
  groups?: any[];
  groupsLoaded?: boolean;
  rank?: any;
  allGames?: any[];
  hhStatuses?: any;
  hhRunning?: any;
  hhSelected?: boolean;
  isHHEligibleBet?: any;
  hhPrizeWon?: any;
  playerProfile?: { client_player_id?: string };
};

const transformTournament = (tournament: any, lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(tournament));

  const newMetaUI: any = {};

  copy.meta.ui &&
    Object.keys(copy.meta.ui).forEach((metaUIKey) => {
      if (copy.meta.ui[metaUIKey] != null && copy.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = copy.meta.ui[metaUIKey].url;
      } else if (copy.meta.ui[metaUIKey] != null && copy.meta.ui[metaUIKey].text != null) {
        if (copy.meta.ui[metaUIKey].text[lang] != null && copy.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = copy.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(copy.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (copy.meta.ui[metaUIKey].text[k]) {
              value = copy.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;

  /*
  if (allGames != null) {
    if (copy.meta && copy.meta.games) {
      copy.meta.games = gameIdsToObjects(allGames, copy.meta.games);
    }
    if (copy.meta && copy.meta.recommended) {
      copy.meta.recommended = gameIdsToObjects(allGames, copy.meta.recommended);
    }
  }
  */

  return copy;
};

const transformTournaments = (tournaments: any[], groupNames: string[], lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(tournaments));

  copy &&
    copy.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy[index].meta.ui = newMetaUI;

      /*
      if (allGames != null) {
        if (copy[index].meta && copy[index].meta.games) {
          copy[index].meta.games = gameIdsToObjects(allGames, copy[index].meta.games);
        }
        if (copy[index].meta && copy[index].meta.recommended) {
          copy[index].meta.recommended = gameIdsToObjects(allGames, copy[index].meta.recommended);
        }
      }
      */

      copy[index].groupName = groupNames[index];
    });

  return copy;
};

const transformGroup = (group: Group, lang: string, allGames?: any[]) => {
  const copy = JSON.parse(JSON.stringify(group));

  copy.tournaments &&
    copy.tournaments.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy.tournaments[index].meta.ui = newMetaUI;

      /*
      if (allGames != null) {
        if (copy.tournaments[index].meta && copy.tournaments[index].meta.games) {
          copy.tournaments[index].meta.games = gameIdsToObjects(allGames, copy.tournaments[index].meta.games);
        }
        if (copy.tournaments[index].meta && copy.tournaments[index].meta.recommended) {
          copy.tournaments[index].meta.recommended = gameIdsToObjects(
            allGames,
            copy.tournaments[index].meta.recommended,
          );
        }
      }
      */
    });

  const newMetaUI: any = {};

  group.meta.ui &&
    Object.keys(group.meta.ui).forEach((metaUIKey) => {
      if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].url;
      } else if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].text != null) {
        if (group.meta.ui[metaUIKey].text[lang] != null && group.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(group.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (group.meta.ui[metaUIKey].text[k]) {
              value = group.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;

  return copy;
};

const emptyArr: any[] = [];
const emmtyObj: any = {};

export const fetchTournamentInfo = (gameId: number, lang = 'en') => {
  const [state, setState] = React.useState<StateProps>({
    hasActive: false,
    hasEnrolled: false,
    tournament: null,
    activeTournaments: [],
    group: null,
    // groupsLoaded: false,
  });

  const dispatch = useAppDispatch();
  const authentication = useAppSelector<any>((state) => state.authentication);
  const groups = useAppSelector<any>((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArr,
  );
  const groupsLoaded = useAppSelector<any>((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  );
  const rank = useAppSelector<any>((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : emmtyObj,
  );

  React.useEffect(() => {
    if (!groupsLoaded) {
      dispatch(loadTournamentsGroups());
    }
  }, []);

  React.useEffect(() => {
    if (!groups || !groups.length) return;

    if (gameId && !isNaN(gameId)) {
      let hasActive = false;
      let hasEnrolled = false;
      let tournament = null;
      let currentGroup = null;
      const activeTournaments: any = [];
      const groupNames: any = [];

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.type !== 2) continue;

        if (typeof group.block_enroll === 'undefined' || !group.block_enroll) {
          for (let j = 0; j < group.tournaments.length; j++) {
            if (group.tournaments[j].activated) {
              if (
                group.tournaments[j].meta &&
                group.tournaments[j].meta.games &&
                group.tournaments[j].meta.games.indexOf(gameId) > -1
              ) {
                currentGroup = group;
                tournament = group.tournaments[j];
                hasActive = true;
                break;
              }
            }
          }
        }
        if (hasActive) break;
      }

      const now = moment().valueOf();

      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.type !== 2) continue;
        if (!(typeof group.block_enroll === 'undefined' || !group.block_enroll)) continue;

        group.tournaments.forEach((t: any) => {
          if (t.start_date <= now && now < t.end_date) {
            if (t.meta && t.meta.games && t.meta.games.indexOf(gameId) > -1) {
              if (typeof group.block_enroll === 'undefined' || !group.block_enroll) {
                activeTournaments.push(t);
                groupNames.push(group.name);
              }
            }
          }
        });
      }

      if (!hasActive) {
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].enrolled) {
                if (
                  group.tournaments[j].meta &&
                  group.tournaments[j].meta.games &&
                  group.tournaments[j].meta.games.indexOf(gameId) > -1
                ) {
                  currentGroup = group;
                  tournament = group.tournaments[j];
                  hasEnrolled = true;
                  break;
                }
              }
            }
          }
          if (hasEnrolled) break;
        }
      }

      if (!hasActive && !hasEnrolled) {
        const now = moment().valueOf();
        const sorted = [...groups].sort((a, b) => {
          const priorityA = a.priority ? a.priority : 0;
          const priorityB = b.priority ? b.priority : 0;
          return priorityB - priorityA;
        });

        for (let i = 0; i < sorted.length; i++) {
          const group = sorted[i];

          if (group.type !== 2) continue;

          if (!group.block_enroll) {
            for (let j = 0; j < group.tournaments.length; j++) {
              if (group.tournaments[j].start_date <= now && now < group.tournaments[j].end_date) {
                if (
                  group.tournaments[j].meta &&
                  group.tournaments[j].meta.games &&
                  group.tournaments[j].meta.games.indexOf(gameId) > -1
                ) {
                  currentGroup = group;
                  tournament = group.tournaments[j];
                  break;
                }
              }
              if (tournament) break;
            }
          }
        }
      }

      let isAuthenticated = false;
      if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
        isAuthenticated = true;
      }

      if (tournament) {
        if (rank && !rank[tournament.id]) {
          dispatch(loadPlayerLeaderBoard({ tournamentId: tournament.id, inGame: true }));
        }

        if (tournament.enrolled && !tournament.activated && isAuthenticated) {
          if (tournament.player_mission_id) {
            dispatch(tournamentActivate({ tournamentId: tournament.player_mission_id }));
          }
        }

        setState({
          hasActive,
          hasEnrolled,
          tournament: transformTournament(tournament, lang),
          activeTournaments: transformTournaments(activeTournaments, groupNames, lang),
          group: transformGroup(currentGroup, lang),
          // groupsLoaded,
        });
      }
    }
  }, [authentication, groups, rank, tournamentActivate, gameId, lang]);

  return state;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);
const emptyArray: any = [];

const GameHeader = (componentProps: GameHeaderProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const rank: any = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.leaderboard.player : {},
  );
  const hhRunning = useAppSelector((state) => state.happyHour.running);
  const hhStatuses = useAppSelector((state) => state.happyHour.status);
  const hhSelected = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.hhSelected : false,
  );
  const isHHEligibleBet = useAppSelector((state) => state.happyHour.betInfo);
  const momentumPrize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum?.prize?.loaded);
  const momentumEligibleGames = useAppSelector<any>((state) =>
    state.momentum?.prize?.eligibleGames ? state.momentum.prize.eligibleGames : emptyArray,
  );
  const inGameHeaderSelection = useAppSelector<any>((state) => state.application.inGameHeaderSelection);

  const challenges = useAppSelector((state) => state.challenges.challenges);
  // const challengesLoaded = useAppSelector((state) => state.challenges.loaded);
  const triggerCelebration = useAppSelector((state) => state.challenges.triggerCelebration);
  const triggerProgressIncrease = useAppSelector((state) => state.challenges.triggerProgressIncrease);

  const matches = useMatches();

  const [state, setState] = React.useState({
    openTournamentInfoModal: false,
    openEnrollModal: false,
  });

  const [challengeExpanded, setChallengeExpanded] = React.useState(0);
  const wasChallangeExpanded = React.useRef(false);
  const [challengeInfoOpened, setChallengeInfoOpened] = React.useState(false);

  const sessionChallenges = React.useRef([]);

  let gameId = props.properties.targetId;

  if (props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      const match = matches[0];
      if (match.params && match.params[props.properties.pathParamKey] != null) {
        gameId = match.params[props.properties.pathParamKey];
      }
    }
  }

  gameId = gameId ? Number(gameId) : gameId;

  const {
    hasActive,
    hasEnrolled,
    tournament,
    activeTournaments,
    group,
    // groupsLoaded
  } = fetchTournamentInfo(gameId, i18n.language);
  const [showHappyHoursTermsAndConditions, setShowHappyHoursTermsAndConditions] = React.useState(false);
  const [extendHappyHourHeader, setExtendHappyHourHeader] = React.useState(false);
  const [extendJackpotHeader, setExtendJackpotHeader] = React.useState(false);

  const {
    data: { data: allTournamentGames },
  } = fetchGamesInfo({
    dsId: window.config.dataSourceAllGames,
    gameIds: tournament?.meta?.games,
    authenticationToken: authentication?.access_token,
  });

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [momentumPrizeLoaded, authentication]);

  React.useEffect(() => {
    if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      if (window?.config?.challengesEnabled === '1') {
        dispatch(fetchChallenges());
      }
    }
  }, [authentication]);

  React.useEffect(() => {
    // expand the first casino challenge that is not completed
    if (challenges && Array.isArray(challenges) && challenges.length && wasChallangeExpanded.current === false) {
      const idx = challenges.findIndex(
        (c: any) => c.challenge_scope.includes('CASINO') && c.enrolled && c.status !== 'completed',
      );

      if (!challengeExpanded) {
        if (idx != null && idx > -1) {
          setChallengeExpanded(challenges[idx].id);
          wasChallangeExpanded.current = true;
        }
      } else {
        // if there is an expanded challenge but got completed in the meantime, expand the next active one
        const expandedIndex = challenges.findIndex((c: any) => c.id === challengeExpanded);
        if (expandedIndex != null && challenges[expandedIndex].status === 'completed') {
          if (idx != null && idx > -1) {
            setChallengeExpanded(challenges[idx].id);
            wasChallangeExpanded.current = true;
          }
        } else {
          // wasChallangeExpanded.current = true;
          // if there is an expanded challenge but not completed, keep it expanded
          return;
        }
      }
    }
  }, [challenges]);

  React.useEffect(() => {
    if (wasChallangeExpanded.current === true) {
      // @ts-ignore
      sessionChallenges.current = challenges
        ?.filter((c: any) => c.challenge_scope.includes('CASINO') && c.enrolled && c.status !== 'completed')
        .map((c: any) => c.id);
    }
  }, [wasChallangeExpanded.current]);

  const allTournamentRecommandedGames: any = [];
  if (allTournamentGames?.length) {
    const hashIds: any = {};
    allTournamentGames.forEach((g: any, index: number) => {
      hashIds[g.id] = index;
    });
    tournament?.meta?.recommended.forEach((id: number) => {
      if (hashIds[id.toString()] != null)
        allTournamentRecommandedGames.push(allTournamentGames[hashIds[id.toString()]]);
    });
  }

  let isAuthenticated = false;
  if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) isAuthenticated = true;

  let hasMomentum: any = false;
  let hasMomentumEligibleGame: any = null;
  if (momentumPrize?.active && gameId) {
    if (momentumPrize?.bonus_expires_at) {
      const now = new Date().valueOf();
      const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
      const expired = now > prizeExpiresAt;

      if (!expired) {
        if (momentumEligibleGames.length > 0 && momentumEligibleGames.includes(parseInt(gameId, 10))) {
          hasMomentum = true;
        }
      }
    }
  }
  if (momentumEligibleGames.length > 0 && gameId) {
    if (momentumEligibleGames.includes(parseInt(gameId, 10))) {
      hasMomentumEligibleGame = true;
    } else {
      hasMomentumEligibleGame = false;
    }
  }

  // get a list of top players (3->5 top players). might include or not the current player -> limit to 3 players
  const getTopPlayers = () => {
    if (tournament && rank && rank[tournament.id] && rank[tournament.id].top) {
      const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;

      const players = JSON.parse(JSON.stringify(rank[tournament.id].top)).map((el: any) => {
        // return JSON.parse(JSON.stringify(rank[tournament.id].top)).map((el: any) => {

        return {
          pos: el.pos,
          nickname: el.nickname,
          score: el.score,
          award: el.award,
          me: !!el.me,
          outside: false,
          avatar:
            el.avatar && typeof el.avatar === 'string' ? el.avatar : el.avatar && el.avatar.url ? el.avatar.url : image,
          postStr: el.pos.toString(),
        };
      });

      if (!Array.isArray(players)) return [];

      // place 1st player in the middle and remove the rest if there are more than 3 players
      if (players.length >= 3) {
        players[0] = players.splice(1, 1, players[0])[0];
        return players.slice(0, 3);
      } else {
        return players;
      }
    }
    return [];
  };

  const getPlayerScoreDiff = (cp: number = 0, p2: number = 0) => {
    if (cp > p2) return '-' + (cp - p2) + ' pts';
    if (cp < p2) return '+' + (p2 - cp) + ' pts';
    if (cp === p2) return '+0 pts';
  };

  const reorderPlayersList = (players: any[], playerIndex: number = -1) => {
    const reorderedPlayers: any[] = [];
    const noPlayer = { hidePlayer: true };

    switch (playerIndex) {
      case 0:
        reorderedPlayers.push(noPlayer);
        reorderedPlayers.push(noPlayer);
        reorderedPlayers.push(players[0]); // player in the middle
        reorderedPlayers.push(
          typeof players[1] === 'object'
            ? {
                ...players[1],
                scoreDiffStr: getPlayerScoreDiff(players[0].score, players[1].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[2] ?? noPlayer);
        break;
      case 1:
        reorderedPlayers.push(noPlayer);
        reorderedPlayers.push(
          typeof players[0] === 'object'
            ? {
                ...players[0],
                scoreDiffStr: getPlayerScoreDiff(players[1].score, players[0].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[1]); // player in the middle
        reorderedPlayers.push(
          typeof players[2] === 'object'
            ? {
                ...players[2],
                scoreDiffStr: getPlayerScoreDiff(players[1].score, players[2].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[3] ?? noPlayer);
        break;
      case 2:
        reorderedPlayers.push(players[0] ?? noPlayer);
        reorderedPlayers.push(
          typeof players[1] === 'object'
            ? {
                ...players[1],
                scoreDiffStr: getPlayerScoreDiff(players[2].score, players[1].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[2]); // player in the middle
        reorderedPlayers.push(
          typeof players[3] === 'object'
            ? {
                ...players[3],
                scoreDiffStr: getPlayerScoreDiff(players[2].score, players[3].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[4] ?? noPlayer);
        break;
      case 3:
        reorderedPlayers.push(players[1] ?? noPlayer);
        reorderedPlayers.push(
          typeof players[2] === 'object'
            ? {
                ...players[2],
                scoreDiffStr: getPlayerScoreDiff(players[3].score, players[2].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[3]); // player in the middle
        reorderedPlayers.push(
          typeof players[4] === 'object'
            ? {
                ...players[4],
                scoreDiffStr: getPlayerScoreDiff(players[3].score, players[4].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[5] ?? noPlayer);
        break;
      case 4:
        reorderedPlayers.push(players[2] ?? noPlayer);
        reorderedPlayers.push(
          typeof players[3] === 'object'
            ? {
                ...players[3],
                scoreDiffStr: getPlayerScoreDiff(players[4].score, players[3].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(players[4]); // player in the middle
        reorderedPlayers.push(
          typeof players[5] === 'object'
            ? {
                ...players[5],
                scoreDiffStr: getPlayerScoreDiff(players[4].score, players[5].score),
              }
            : noPlayer,
        );
        reorderedPlayers.push(noPlayer);
        break;
      default:
        reorderedPlayers.push(players[0] ?? noPlayer);
        reorderedPlayers.push(players[1] ?? noPlayer);
        reorderedPlayers.push(players[2] ?? noPlayer);
        reorderedPlayers.push(players[3] ?? noPlayer);
        reorderedPlayers.push(players[4] ?? noPlayer);
        break;
    }
    return reorderedPlayers;
  };

  // get the list of players around and including the current player and limit to 4 players
  const getPlayers = (noSlice: boolean = false, reorderList: boolean = false) => {
    if (tournament && rank && rank[tournament.id] && rank[tournament.id].player) {
      const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;

      const players = JSON.parse(JSON.stringify(rank[tournament.id].player)).map((el: any) => {
        return {
          pos: el.pos,
          nickname: el.nickname,
          score: el.score,
          award: el.award,
          me: !!el.me,
          outside: false,
          avatar:
            el.avatar && typeof el.avatar === 'string' ? el.avatar : el.avatar && el.avatar.url ? el.avatar.url : image,
          postStr: el.pos.toString(),
        };
      });

      let playerIndex = -1;
      playerIndex = players.findIndex((p: any) => p.me);

      if (!Array.isArray(players)) return [];

      if (noSlice && !reorderList) return players;

      if (reorderList) {
        // console.log('DEBUG PLAYERS LIST REORDER', { players, reorderedPlayers });
        if (players.length > 0) {
          // function to reorder the list of players
          return reorderPlayersList(players, playerIndex);
        } else {
          return players;
        }
      }

      // get player index in the list
      // let playerIndex = -1;
      // for (let i = 0; i < players.length; i++) {
      //   if (players[i].me) {
      //     playerIndex = i;
      //     break;
      //   }
      // }

      // if player in not in the first 4 players, show the 3 players before and the player
      if (players.length > 4) {
        if (playerIndex >= 4) {
          return players.slice(playerIndex - 3, playerIndex + 1);
        } else {
          return players.slice(0, 4);
        }
      } else {
        return players;
      }
    }
    return [];
  };

  // handled joining/enrolling to an active tournament
  const handleJoinEnroll = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (tournament && !tournament.enrolled) {
      dispatch(tournamentEnroll({ tournamentId: tournament.id, activate: true }));
    }
  };

  // hide/show the popup with a summary about the tournament
  const toggleTournamentInfoModal = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    setState((v) => ({
      ...v,
      openTournamentInfoModal: !v.openTournamentInfoModal,
    }));
  };

  // hide/show the popup from where a player can switch between active tournments or happy hour
  const toggleEnrollModal = (e?: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    setState((v) => ({
      ...v,
      openEnrollModal: !v.openEnrollModal,
    }));
  };

  const handleJoinEnrollTournament = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleHappyHoursSelected(false));
    const target = e.currentTarget;

    if (target) {
      const id: string | undefined = target.dataset.id;
      const type = target.dataset.type;
      if (id != null) {
        if (type === 'enroll') {
          dispatch(
            tournamentEnroll({
              tournamentId: parseInt(id, 10),
              activate: true,
              cb: () => {
                toggleEnrollModal();
              },
            }),
          );
          return;
        } else {
          dispatch(
            tournamentActivate({
              tournamentId: parseInt(id, 10),
              cb: () => {
                toggleEnrollModal();
              },
            }),
          );
          return;
        }
      }
    }
    toggleEnrollModal();
  };
  const handleHappyHourJoin = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleHappyHoursSelected(true));
    toggleEnrollModal();
  };

  const onChallengeExpand = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget;
    if (target?.dataset?.id) {
      const id: string | undefined = target.dataset.id;

      if (parseInt(id, 10) === challengeExpanded) {
        setChallengeExpanded(0);
        return;
      }
      setChallengeExpanded(parseInt(id, 10));
    }
  };

  const onSwitchToWidget = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.currentTarget;
    if (target) {
      const id: string | undefined = target.dataset.id;
      dispatch(setInGameHeaderSelection(id ?? ''));
    }
  };

  const hhIsRunning = hhRunning && hhRunning['online-slots'] ? hhRunning['online-slots'] : false;
  const hhStatus = hhIsRunning && hhStatuses && hhStatuses['online-slots'] ? hhStatuses['online-slots'] : null;

  const getTexts = () => {
    const topPlayers = getTopPlayers();
    const texts = [];

    if (tournament) {
      texts.push(tournament.name);

      if (tournament.meta && tournament.meta.ui) {
        texts.push(`${tournament.meta.ui.prize_1} ${tournament.meta.ui.prize_2}`);
      }
    }

    if (Array.isArray(topPlayers)) {
      for (let i = 0; i < 3; i++) {
        if (topPlayers.length > i && topPlayers[i] && topPlayers[i].award) {
          texts.push(`${t('Position')} #${topPlayers[i].pos}: ${topPlayers[i].award}`);
        }
      }
    }

    return texts;
  };

  const isBetEligible = () => {
    if (isHHEligibleBet && isHHEligibleBet['online-slots']) {
      if (isHHEligibleBet['online-slots'].eligible) {
        return true;
      }
      return false;
    }
    return true;
  };

  const getTournamentCalendarInfo = (tournament: any) => {
    if (!tournament) return null;
    // if Happy Hour, show the Happy Hour info
    if (typeof tournament.duration !== 'undefined') {
      return `${moment(tournament.start_time * 1000).format('DD')} ${t(
        moment(tournament.start_time * 1000).format('MMM'),
      )} - ${moment(tournament.start_time * 1000).format('DD')} ${t(
        moment((tournament.start_time + tournament.duration) * 1000).format('MMM'),
      )}`;
    } else {
      // if not Happy Hour, show the tournament info
      return `${moment(tournament.start_date).format('DD')} ${t(
        moment(tournament.start_date).format('MMM'),
      )} - ${moment(tournament.end_date).format('DD')} ${t(moment(tournament.end_date).format('MMM'))}`;
    }
  };

  const toFixedWithoutZeros = (num: number, precision: number) => `${Number.parseFloat(num.toFixed(precision))}`;
  const parseMomentumPrize = (prize: any) => {
    if (prize && hasMomentum && hasMomentumEligibleGame) {
      const starProgress = momentumProgressDetails(momentumPrize?.wager_progress ?? 0, starThresholds);
      const stars = [...Array(starThresholds.length).keys()].map((index) => {
        return {
          active: index < starProgress.stars,
        };
      });

      const value = toFixedWithoutZeros((momentumPrize?.reward?.value || 0) / 100, 2);
      const currency = formatCurrency(momentumPrize?.reward?.currency || '');
      const progress = starProgress?.progress ?? 0;
      const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();

      return {
        value: value,
        currency: currency,
        progress: progress,
        stars: stars,
        expiresAt: prizeExpiresAt,
      };
    }
    return null;
  };

  const parseTournamentPrize = (tournament: any) => {
    if (tournament) {
      let player: any = null;
      let pointsCurrent = 0;

      let rankAbove = 0;
      let rankBelow = 0;
      let rankBelow2 = 0;

      const players = getPlayers(true);
      players?.forEach((p: any, index) => {
        if (p.me) {
          player = p;

          pointsCurrent = p.score;

          if (players[index - 1]) {
            rankAbove = players[index - 1].score;
          }

          if (players[index + 1]) {
            rankBelow = players[index + 1].score;
          }
          if (players[index + 2]) {
            rankBelow2 = players[index + 2].score;
          }
        }
      });

      /*
          percentage = (myRank - rankBelow) / (rankAbove - rankBelow). Safeguards for divide by 0 
          and last/first place have to be enforced. Divide by 0 must display ((rank MOD  11) * 9). 

          rank #1 will use percentage = (1 - ((myRank - rankBelow) / (myRank - rankBelow2)) ); or 
          we could use a timer-down representation (progress bar decreases to reach 0% instead 
          increasing to reach 100%).

          1200 1000 800
          (200/400) * 100 = 50%

          200 / 400 = 0.5 * 100 = 50%

          100 / 400 = 0.25 * 100 = 25%
      */

      let progress = 0;

      if (player) {
        const total = rankAbove - rankBelow;
        const current = pointsCurrent - rankBelow;

        progress = (current * 100) / (total || 1);

        if (player.pos === 1) {
          const r1 = pointsCurrent - rankBelow;
          const r2 = pointsCurrent - rankBelow2;

          progress = (r1 / (r2 || 1)) * 100;
        }
      }

      if (player) {
        return {
          progress: progress,
          position: player.pos,
          value: player.award,
        };
      }
    }
    return null;
  };

  const parseHappyHourPrize = (happyHour: any) => {
    if (happyHour?.prize_current) {
      const startTimestamp = happyHour?.start_time * 1000;
      const endTimestamp = startTimestamp + happyHour?.duration * 1000;
      const now = new Date().valueOf();

      const percent100 = endTimestamp - startTimestamp;
      const percentNow = now - startTimestamp;

      const percent = (percentNow / percent100) * 100;

      return {
        progress: percent,
        value: happyHour?.prize_current?.amount,
        currency: 'Lei',
      };
    }
    return null;
  };

  const getChallengeProgressData = (challenge: any) => {
    let cp = 0;
    let tp = 100;

    const current = challenge?.value ?? 0;
    const target = challenge?.max_value ?? 0;

    if (current) cp = parseFloat(current);
    if (target) tp = parseFloat(target);

    const percent = (cp * 100) / tp;
    if (isNaN(percent)) {
      return [0, 100];
    }

    cp = percent;
    tp = 100;

    return [tp, cp];
  };
  const parseChallengesData = (challenges: any) => {
    if (!wasChallangeExpanded.current) return null;

    if (Array.isArray(challenges) && challenges.length > 0) {
      const prizes: any = {};
      const challengesPrizes: any = [];
      let value = 0;
      let currency: any = '';

      let totalProgress = 0;
      let currentProgress = 0;
      let totalActiveChallenges = 0;

      challenges.forEach((challenge: any) => {
        if (challenge) {
          if (
            challenge.enrolled &&
            challenge.type === 1 &&
            (challenge.status === 'active' || challenge.status === 'completed') &&
            challenge.challenge_scope.includes('CASINO') &&
            Array.isArray(sessionChallenges.current) &&
            sessionChallenges.current.length > 0 &&
            //@ts-ignore
            sessionChallenges.current.includes(challenge.id)
          ) {
            let process = false;

            if (challenge.meta?.games?.length > 0) {
              if (challenge.meta.games.includes(gameId)) {
                process = true;
              }
            }

            if (!process) return;

            if (challenge.status === 'active') {
              totalActiveChallenges++;
            }

            if (challenge.prize) {
              const prize = challenge.prize;
              const displayCurrency = prize.prize_display_currency;

              // display short name
              let displayCurrencyCode = '';
              if (displayCurrency && displayCurrency.art_bundle && Array.isArray(displayCurrency.art_bundle)) {
                const obj = displayCurrency.art_bundle[0] ?? null;

                if (obj && obj.text_display_name_short) {
                  displayCurrencyCode = obj.text_display_name_short.text ?? { ro: '', en: '' };
                  currency = displayCurrency.currency_code;
                } else {
                  if (obj && obj.text_display_name) {
                    displayCurrencyCode = obj.text_display_name.text ?? { ro: '', en: '' };
                    currency = displayCurrency.currency_code;
                  }
                }
              }

              if (currency) {
                if (!prizes[currency]) {
                  prizes[currency] = {
                    value: 0,
                    currency: displayCurrencyCode,
                    currencyCode: currency,
                  };
                }
              }
              prizes[currency].value += parseFloat(prize.prize_value);

              const progress = (challenge.value * 100) / challenge.max_value;

              const labels = formatProgressString(
                challenge.value,
                challenge.max_value,
                challenge.display_progress_as ?? 'ABSOLUTE',
                // currency,
                // displayCurrencyCode,
                challenge.meta.ui.progress_currency?.text ?? '',
              );

              challengesPrizes.push({
                ...challenge,
                progress: challenge.status === 'completed' ? 100 : progress,
                expanded: challengeExpanded,
                triggerProgressIncrease:
                  triggerProgressIncrease &&
                  challenge.player_mission_id &&
                  triggerProgressIncrease.toString() === challenge.player_mission_id.toString()
                    ? true
                    : false,
                onChallengeExpand,
                ...(labels ?? {}),
              });
            }

            const [tp, cp] = getChallengeProgressData(challenge);
            totalProgress += challenge.status === 'completed' ? 100 : tp;
            currentProgress += challenge.status === 'completed' ? 100 : cp;
          }
        }
      });

      const keys = Object.keys(prizes);
      if (keys.length === 0) return null;

      const prizesList: any = [];

      // sort challenges prizes by status -> completed first, then active
      challengesPrizes.sort((a: any, b: any) => {
        if (a.status === 'completed' && b.status !== 'completed') return -1;
        if (a.status !== 'completed' && b.status === 'completed') return 1;
        return 0;
      });

      keys.forEach((key) => {
        prizesList.push(prizes[key]);

        if (prizes[key].value > value) {
          value = prizes[key].value;
        }
      });

      return {
        progress: (currentProgress * 100) / totalProgress,
        challenges: challengesPrizes,
        currency: currency,
        value: value,
        prizesList,
        totalActiveChallenges,
      };
    }
    return null;
  };

  let canEnrollModal = false;
  if (hhIsRunning) {
    canEnrollModal = true;
  } else if (activeTournaments.length > 0) {
    canEnrollModal = true;
  } else if (hasMomentumEligibleGame != null) {
    canEnrollModal = true;
  }

  const currentTournament = tournament
    ? {
        ...tournament,
        meta: tournament.meta
          ? {
              ...tournament.meta,
              games:
                tournament.meta && tournament.meta.games && gameIdsToObjects(allTournamentGames, tournament.meta.games),
              recommended:
                tournament.meta &&
                tournament.meta.recommended &&
                gameIdsToObjects(allTournamentRecommandedGames, tournament.meta.recommended),
            }
          : {},
      }
    : null;

  let inGameHeaderActiveEvents = 0;
  if (hasMomentum) inGameHeaderActiveEvents++;
  if (hhIsRunning) inGameHeaderActiveEvents++;
  if (parseChallengesData(challenges) != null) inGameHeaderActiveEvents++;
  if (currentTournament != null) inGameHeaderActiveEvents++;

  React.useEffect(() => {
    // if only 1 event is active -> open that event directly
    if (inGameHeaderActiveEvents === 0) {
      dispatch(setInGameHeaderSelection(''));
      return;
    }

    if (inGameHeaderActiveEvents === 1) {
      if (currentTournament != null) dispatch(setInGameHeaderSelection('tournament'));
      if (parseChallengesData(challenges) != null) dispatch(setInGameHeaderSelection('challenges'));
      if (hhIsRunning) dispatch(setInGameHeaderSelection('happy-hour'));
      if (hasMomentum) dispatch(setInGameHeaderSelection('turbo-cash'));
    }
  }, [
    inGameHeaderActiveEvents,
    hasMomentum,
    hhIsRunning,
    challenges,
    currentTournament,
    wasChallangeExpanded.current,
    challengeExpanded,
    sessionChallenges.current,
  ]);

  React.useEffect(() => {
    if (triggerCelebration) {
      setTimeout(() => {
        dispatch(resetChallengeCelebration());
      }, 10000);
    }
  }, [triggerCelebration]);

  React.useEffect(() => {
    if (triggerProgressIncrease) {
      setTimeout(() => {
        dispatch(resetChallengeProgressIncrease());
      }, 2000);
    }
  }, [triggerProgressIncrease]);

  if (!isAuthenticated) return null;
  if (!gameId) return null;

  const contextValue = {
    hasActive: hasActive,
    hasEnrolled: hasEnrolled,
    currentTournament: currentTournament,
    group: group,
    url: group ? group.url : null,
    name: group ? group.name : null,
    topPlayers: getTopPlayers(),
    players: getPlayers(),
    orderedPlayers: getPlayers(true, true),
    handleJoinEnroll: handleJoinEnroll,
    toggleTournamentInfoModal: toggleTournamentInfoModal,
    canEnrollModal: canEnrollModal,
    toggleEnrollModal: toggleEnrollModal,
    openTournamentInfoModal: state.openTournamentInfoModal,
    openEnrollModal: state.openEnrollModal,
    tournaments: activeTournaments
      ? activeTournaments.map((t: any) => ({
          ...t,
          handleJoinEnrollTournament: handleJoinEnrollTournament,
          happyHourSelected: hhSelected,
          dateString: getTournamentCalendarInfo(tournament),
        }))
      : [],
    happyHourSelected: hhSelected,
    happyHourIsRunning: hhIsRunning,
    happyHour: hhStatus,
    isHHEligibleBet: isBetEligible(),
    happyHourDateString: getTournamentCalendarInfo(hhStatus),
    handleHappyHourJoin,
    showHappyHoursTermsAndConditions: showHappyHoursTermsAndConditions,
    toggleHappyHoursTermsAndCondition: () => {
      setShowHappyHoursTermsAndConditions((v) => !v);
    },
    extendHappyHourHeader: extendHappyHourHeader,
    toggleExtendHappyHourHeader: () => {
      setExtendHappyHourHeader((pS) => !pS);
    },
    scrollingTexts: getTexts(),
    extendJackpotHeader: extendJackpotHeader,
    toggleExtendJackpotHeader: () => {
      setExtendJackpotHeader((pS) => !pS);
    },
    jackpot: {}, //TODO: add jackpot data
    onSwitchToWidget: onSwitchToWidget,
    inGameHeaderSelection,

    hasMomentum,
    hasMomentumEligibleGame,

    momentumPrize: parseMomentumPrize(momentumPrize),
    tournamentPrize: parseTournamentPrize(tournament),
    happyHourPrize: parseHappyHourPrize(hhStatus),
    challengesPrize: parseChallengesData(challenges),

    challengeInfoOpened,
    onChallengeInfoToggle: () => {
      setChallengeInfoOpened((v) => !v);
    },

    inGameHeaderActiveEvents: inGameHeaderActiveEvents ?? 0,
    triggerProgressIncrease,
    triggerCelebration,
  };

  // console.log('GameHeader[contextValue]', {
  //   contextValue,
  //   // challengeExpanded,
  //   inGameHeaderActiveEvents,
  //   // triggerCelebration,
  //   // triggerProgressIncrease,
  //   // wasChallangeExpanded,
  //   // sessionChallenges,
  //   // momentumPrizeLoaded,
  //   // challengesLoaded,
  //   // groupsLoaded,
  // });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default GameHeader;
