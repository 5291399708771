import React from 'react';

import { createBrowserRouter, RouterProvider, Outlet, useLocation, Link, ScrollRestoration } from 'react-router-dom';

import PageLayout from './components/page-layout/page-layout';
import Page from './components/page/page';
import { useAppSelector } from './store';
import StyleIds from './constants/css-ids';
import PageLoader from './components/page-loader';
import ActionUIHandler from './components/action-handler';
import AccountProblem from './components/modules/account-problem';
import Notifications from './components/notification';
import Popups from './components/popups/popups';
import RouteChangeHandler from './components/route-change-handler';
import AdminCustomization from './components/admin-customization';
import LiveAgent from '@/components/live-agent';
import ZendeskChatAgent from '@/components/zendesk-chat';

type BaseElementProps = {
  children?: any;
};

const BaseElement = (props: BaseElementProps) => {
  const isLiveAgentEnabled = window.config.customerSupport?.liveChatEnabled === 'liveAgent';
  const isZendeskChatEnabled = window.config.customerSupport?.liveChatEnabled === 'zendesk';

  return (
    <React.Fragment>
      <Outlet />
      <AccountProblem />
      <ActionUIHandler />
      <Notifications />
      { isLiveAgentEnabled && <LiveAgent /> }
      { isZendeskChatEnabled && <ZendeskChatAgent /> }
      <ScrollRestoration />
      <Popups />
      <RouteChangeHandler />
      {window !== window.parent && <AdminCustomization />}
    </React.Fragment>
  );
};

const ErrorElement = (props: any) => {
  console.error('ErrorElement', props);
  const location = useLocation();
  console.log(location);
  return (
    <div className="d-flex flex-column align-items-center mt-4 justify-content-start w-100 h-100">
      <div>500. That&apos;s an error.</div>
      <div>The requested URL {location.pathname} was not found on this server or something is wrong with it.</div>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export let storedRouter: any = null;

const Routes: React.FC = () => {
  const config = useAppSelector((state) => state.templatesConfig);

  if (!config.loaded) {
    return <PageLoader />;
  }

  const routes: any[] = [];
  Object.keys(config.setPages).forEach((url) => {
    if (url === '*') return;
    const page = config.setPages[url];

    if (page.page_layout_id) {
      routes.push({
        path: url,
        element: <PageLayout id={page.page_layout_id} pageId={page.page_id} />,
        errorElement: <ErrorElement />,
      }); 

      return;
    }

    routes.push({
      path: url,
      element: <Page id={page.page_id} />,
      errorElement: <ErrorElement />,
    });
  });

  if (typeof config.setPages['*'] !== 'undefined') {
    // we have a global page handler
    const page = config.setPages['*'];

    if (page.page_layout_id) {
      routes.push({
        path: '*',
        element: <PageLayout id={page.page_layout_id} pageId={page.page_id} />,
        errorElement: <ErrorElement />,
      });
    } else {
      routes.push({
        path: '*',
        element: <Page id={page.page_id} />,
        errorElement: <ErrorElement />,
      });
    }
  }

  const router = createBrowserRouter([
    {
      element: <BaseElement />,
      children: routes,
    },
  ]);
  storedRouter = router;

  return <RouterProvider router={router} />;
};

export default Routes;
